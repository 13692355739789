import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/firebase/auth.js';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: () => import('../views/Onboarding.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/home',
    component: () => import('../views/Distributors/Home.vue'),
    meta: {
      requiresAuth: true
    },
    children:[
      {
        path: 'list',
        name: 'List-sistributors',
        component: () => import('../views/Distributors/Distributors.vue'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: ':id/price-list',
        name: 'Price-list',
        component: () => import('../views/Distributors/Price-list.vue'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'checkout',
        name: 'Checkout',
        component: () => import('../views/Distributors/Checkout.vue'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/',
        redirect: '/home/list',
      },
    ]
  },
  {
    path: '/orders',
    component: () => import('../views/Orders/Orders.vue'),
    meta: {
      requiresAuth: true
    },
    children:[
      {
        path: 'list',
        name: 'List-orders',
        component: () => import('../views/Orders/Orders-list.vue'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/',
        redirect: '/orders/list',
      },
    ]

  },
  { path: '*', redirect: to => {return '/';}},
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: "active-tab",
  base: process.env.BASE_URL,
  routes
})

import Floppy from '@/helpers/IndexedDB.js';
import Api from '@/helpers/api.js';
import store from '@/store';

router.beforeEach(async (to, from , next) => {
  try {
    let isAuth = auth.currentUser();
    if(!isAuth){ await auth.isLogin(); }
    isAuth = auth.currentUser();
    let floppyUser = await Floppy.getAll('users');
    if(isAuth !== null && floppyUser.length > 0){
      update_profile(floppyUser[0]);
      store.commit('SET_USER', floppyUser[0]);
      let shoppingCart = localStorage.getItem('shoppingCart');
      if(shoppingCart === null){
        store.commit('CLEAR_CART', {'user_id':floppyUser[0].user_id,'address_id':floppyUser[0].address_id});
      }
    }
    if(isAuth !== null && to.path === '/'){
      next('/home')
    }
    if( (isAuth === null && to.path !== '/') || floppyUser.length <= 0 ){
      logout();
    }
    next();
  } catch (error) {
    logout();
  }

  async function logout(){
    Floppy.truncate('users');
    localStorage.clear('key');
    localStorage.clear('shoppingCart');
    await auth.logout();
    store.commit('USER_LOGGED', false);
    store.commit('APP_LOADER', false);
    next('login');
  }
  async function update_profile(floppy) {
    Api.post('users/profile/',{user_id: floppy.user_id}).then((result)=> {
      if(result.status < 300) {
        floppy.name = result.data.user.first_name;
        floppy.username = result.data.user.email;
        floppy.email = result.data.user.email;
        floppy.user_id = result.data.user.id;
        floppy.status = result.data.status.name;
        floppy.picture = result.data.picture;
        floppy.identification_number = result.data.identification_number;
        floppy.phone = result.data.phone;
        floppy.firebase_uid = result.data.firebase_uid;
        floppy.type_user = result.data.type_user.name;
        update_address(floppy);
      }else{
        console.log('This is error ',result.detail);
        logout();
      }
    }).catch((error)=> {
      console.log('This is error ',error);
      if(error.detail == 'User account is disabled.' ){
        Vue.notify({
          type: 'error',
          title: '<div style="font-size:1.25rem;font-weight:500;">Error!</div>',
          text: '<div style="font-size:1rem;font-weight:400;">Su cuenta ha sido desactivada, ponte en contacto con el administrador.</div>'
        })
        logout();
      }
    })
  }
  async function update_address(floppy) {
    Api.post('users/address/', {user_id:floppy.user_id}).then((result)=> {
      if(result.status < 300) {
        floppy.address_id = result.data.id;
        floppy.address_alias = result.data.address_alias;
        floppy.address_detail = result.data.address_detail;
        floppy.lat = result.data.lat;
        floppy.lon = result.data.lon;
        floppy.city = result.data.city;
        floppy.address = result.data.address;
        store.commit('SET_USER', floppy);
        Floppy.update('users', floppy, floppy.firebase_uid);
        store.commit('USER_LOGGED', true);
      }else{
        console.log('This is error ',result.detail);
        store.commit('USER_LOGGED', false);
        next('/onboarding');
      }
    }).catch((error)=> {
      console.log('This is error ',error);
    })
  }
})
export default router
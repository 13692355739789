<template>
  <v-app>
    <notifications class="my-5" width="80%" position="top center" ></notifications>

    <v-overlay color="pediidos" absolute z-index="100" :value="appLoader">
      <v-progress-circular width="10" indeterminate size="300">
        <v-progress-circular width="5" rotate="180" indeterminate size="150">
        </v-progress-circular>
      </v-progress-circular>
    </v-overlay>

    <v-app-bar app color="pediidos" dark v-if="userLogged">
      <div class="d-flex">
        <v-img alt="Logo" contain transition="scale-transition" max-width="180px" max-height="65px" :src="logo_url"/>
      </div>
      <template v-slot:extension>
        <v-tabs >
          <v-tab to="/home">{{$t('_home')}}</v-tab>
          <v-tab to="/orders">{{$t('_orders')}}</v-tab>
        </v-tabs>
        <v-spacer></v-spacer>

        <v-menu rounded="b-lg" bottom offset-y  width="500" >
        <template v-slot:activator="{ on, attrs }">
          <v-badge :content="bage" color="grey darken-1" overlap >
            <v-btn class="mx-auto" icon dark  v-on="on" v-bind="attrs"> <v-icon>mdi-bell-ring</v-icon></v-btn>
          </v-badge>
        </template>
        <v-list>
          <v-list-item v-if="notifications.length === 0" >
            <v-list-item-title>{{$t('empty_notifications')}}</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="(notify, value) in notifications.orders" :key="value" @click="goOrder(value)" >
            <v-list-item-title>{{notify.status}} - {{value}} </v-list-item-title>
          </v-list-item>
        </v-list>
        </v-menu>

        <v-menu rounded="b-lg" bottom offset-y  width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-auto" icon dark  v-on="on" v-bind="attrs"> <v-icon>mdi-translate</v-icon></v-btn>
        </template>
          <v-list dense dark color="pediidos lighten-1">
            <v-list-item-group mandatory v-model="indexLocale" >
              <v-list-item color="white" v-for="(item, index) in locales" :key="index" @click="chageLocate(item.locale)" >
                <v-list-item-content>
                      <v-avatar tile >
                        <v-img :src="item.ico3"></v-img>
                      </v-avatar>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-menu rounded="b-lg" fixed offset-y :close-on-content-click="false" width="80%" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-auto" icon dark v-on="on" v-bind="attrs" ><v-icon>mdi-map-marker</v-icon></v-btn>
          </template>
          <v-card>
            <v-img :src="previewMap()" ></v-img>
            <v-list-item three-line>
              <v-list-item-content>
                <v-card-text class="pa-0">
                  <div class="mb-2 subtitle-2">{{user.address}}</div>
                  <v-divider></v-divider>
                  <div class="mt-2 body-1"><b>{{$t('detail')}}:</b> {{user.address_detail}}</div>
                  <div class="body-1"><b>{{$t('alias')}}:</b> {{user.address_alias}}</div>
                </v-card-text>
              </v-list-item-content>
            </v-list-item>
          <v-card-actions>
            <EditAddress id_map="map-navbar" ></EditAddress>
          </v-card-actions>
          </v-card>
        </v-menu>
        <v-btn class="mx-auto" icon dark @click.stop="drawer = true"><v-icon>mdi-cart-arrow-down</v-icon></v-btn>
      </template>
      <v-spacer></v-spacer>
      <v-menu rounded="b-lg" bottom offset-x offset-y :close-on-click="false" max-width="250" >
        <template v-slot:activator="{ on, attrs }">
          <v-list-group class="mr-n2" v-on="on" v-bind="attrs" color="white" >
            <template v-slot:activator>
              <v-list-item-title class="text-end" style="max-width:37vw;" >{{user.name}}</v-list-item-title>
              <v-list-item-avatar class="ml-2 mr-n4" >
                <v-img :src="user.picture"></v-img>
              </v-list-item-avatar>
            </template>
          </v-list-group>
        </template>
        <v-list>
          <v-list-item @click="goProfile()">
            <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
            <v-list-item-title>{{$t('my_profile')}}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('_logout')}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer width="90%" style="max-width:600px;" overlay-color="pediidos" v-model="drawer" :permanent="drawer" right app temporary >
      <v-list two-line>
        <v-list-item >
          <v-list-item-avatar class="mr-1 ">
            <v-icon class="grey darken-1" dark>mdi-cart-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content v-if="cart.orden.length > 0">
            <v-list-item-title>{{$t('cart.trolley')}} ( {{cart.orden.length}} {{$t('cart.sellers')}} )</v-list-item-title>
            <v-list-item-subtitle>{{cart.usuario.quanty}} {{$t('cart.articles')}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-else >
            <v-list-item-title>{{$t('cart.empty_cart')}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn x-small outlined dark icon color="error" @click.stop="drawer = false">
              <v-icon >mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <template v-if="cart.orden.length > 0">
        <v-list >
          <v-list-group color="pediidos" v-for=" item in cart.orden" :key="item.shop" no-action>
            <template  v-slot:activator>
              <v-list-item-avatar>
                <v-img :src="item.shop_picture"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{item.shop_name}}</v-list-item-title>
                <v-list-item-subtitle class="subtitle-2" >{{item.cant_total_products}} {{$t('cart.articles')}}: <b>{{item.subtotal | formatCurrency}}</b></v-list-item-subtitle>
                <v-list-item-subtitle>{{$t('cart.delivery_cost')}}: {{item.delivery_cost | formatCurrency}}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <v-divider></v-divider>
            <template v-for="child in item.products" >
              <v-list-item v-if="child.cant > 0" three-line class="px-5" :key="child.product_id" >
                <v-list-item-avatar>
                  <v-img :src="child.picture"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-card-text class="subtitle-2 text-uppercase text-ellipsis pa-0" v-text="child.name"></v-card-text>
                  <v-list-item-subtitle>{{$t('cart.unit')}}: {{child.price | formatCurrency}}</v-list-item-subtitle>
                  <v-list-item-subtitle class="subtitle-2" >{{ child | calcTotal}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class=" align-center " >
                  <v-btn x-small outlined dark icon color="success" @click="changeProductCart('add',child,item.shop)" >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-list-item-title class="my-1" >{{child.cant}}</v-list-item-title>
                  <v-btn x-small outlined dark icon color="error" @click="changeProductCart('rem',child,item.shop)" >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>
        <v-divider></v-divider>
        <v-list >
          <v-list-item >
            <v-list-item-content>
              <v-list-item-title>{{$t('cart.subtotal')}}:</v-list-item-title>
              <v-list-item-title>{{$t('cart.shipments')}}:</v-list-item-title>
              <v-list-item-title>{{$t('cart.total')}}:</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="align-right">
              <v-list-item-title class="text-right" >{{cart.usuario.subtotal | formatCurrency}}</v-list-item-title>
              <v-list-item-title class="text-right" >{{(cart.usuario.total-cart.usuario.subtotal) | formatCurrency}}</v-list-item-title>
              <v-list-item-title class="text-right" >{{cart.usuario.total | formatCurrency}}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class=" justify-center" >
            <v-dialog v-model="dialogCancelOrder" persistent max-width="300px" >
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined rounded color="error" v-bind="attrs" v-on="on" >{{$t('cart.cancel_order')}}<v-icon right>mdi-cart-remove</v-icon></v-btn>
              </template>
              <v-card>
                <v-card-title class="text-center align-center justify-center " >{{$t('cart.qn_cancel_order')}}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="pediidos" text @click="dialogCancelOrder = false">{{$t('cancel')}}</v-btn>
                  <v-btn color="pediidos" text @click="cancelOrder()">{{$t('confirm')}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list-item>
          <v-list-item class=" justify-center " >
            <v-btn outlined rounded color="pediidos" @click="goCheckout()" >{{$t('checkout')}}<v-icon right>mdi-cart-arrow-right</v-icon></v-btn>
          </v-list-item>
        </v-list>
      </template>
      <v-sheet v-else class="text-center align-center justify-center " >
        <v-icon size="250" style="margin-top: 80px;" >mdi-cart</v-icon>
        <p class=" my-5" >{{$t('cart.msg_empty_cart')}}</p>
        <v-btn outlined large rounded color="pediidos" @click="goShoping()" >{{$t('cart.go_to_shoping')}}</v-btn>
      </v-sheet>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  import EditAddress from '@/components/EditAddress.vue';
  import auth from '@/firebase/auth.js';
  import { database } from '@/firebase/InitFirebase.js';
  import Utils from '@/helpers/Utils.js';
  import Floppy from '@/helpers/IndexedDB.js';
  import { mapMutations, mapState } from 'vuex';
  import { Howl } from 'howler';
  import * as constants from '@/constants';
  export default {
    name: 'App',
    components: { EditAddress },
    data: () => ({
        drawer: false,
        dialogCancelOrder:false,
        notifications: [],
        bage: "",
        sound: new Howl({src: require("@/assets/sounds/Pediidos_sound.mp3"), volume: 1}),
        indexLocale: 0,
        locales: constants.SUPPORTED_LOCALE,
        logo_url: constants.LOGO_URL_W,
        company_name: constants.COMPANY_NAME,
        favicon: constants.FAVICON,
        color: constants.COLOR,
        allow_sound: constants.ALLOW_SOUND,
    }),
    filters: {
        formatCurrency(currency) {
            return Utils.formatCurrency(currency);
        },
        calcTotal(product){
          let total = (parseFloat(product.price) * parseInt(product.cant));
          return Utils.formatCurrency(String(total));
        },
    },
    async mounted(){
      document.querySelector('meta[name="theme-color"]').setAttribute("content", this.color);
      document.querySelector("link[rel~='icon']").setAttribute("href", this.favicon);
      document.title = this.company_name;
      this.chageLocate(this.$i18n.locale);
      setTimeout(()=>{this.listenNotify();}, 1000);
      if (localStorage.getItem('shoppingCart')) {
        try {
          const newCart = JSON.parse(localStorage.getItem('shoppingCart'));
          this.SET_CART(newCart);
        } catch(e) {
          this.CLEAR_CART({'user_id':this.user.user_id,'address_id':this.user.address_id});
        }
      }
    },
    methods: {
      ...mapMutations(['APP_LOADER','USER_LOGGED','SET_USER','SET_CART','CLEAR_CART','ROVOVE_CART_ZEROS','UPDATE_QUANTY_PRODUCTS']),
      changeProductCart(action,child,shop){
        let myQuanty = parseInt( child.cant );
        child.cant = ( (action == 'add') ? (myQuanty+1) : (myQuanty-1) );
        this.UPDATE_QUANTY_PRODUCTS({'shop':shop});
        if(child.cant < 1){
          this.ROVOVE_CART_ZEROS({'shop':shop,'product':child.product_id});
        }
        this.SET_CART(this.cart);
      },
      cancelOrder(){
        this.CLEAR_CART({'user_id':this.user.user_id,'address_id':this.user.address_id});
        this.products.forEach(function(p){p.quanty = 0});
        this.dialogCancelOrder = false;
        this.drawer = false;
      },
      goShoping(){
        this.drawer = false;
        if(!this.$route.path.includes('/home')){
          this.$router.push('/home');
        }
      },
      goCheckout(){
        this.drawer = false;
        if(!this.$route.path.includes('/checkout')){
          this.$router.push({name: 'Checkout'});
        }
      },
      goProfile(){
        this.drawer = false;
        if(!this.$route.path.includes('/profile')){
          this.$router.push({name: 'Profile'});
        }
      },
      previewMap() {
        let lat = this.user.lat;
        let lon = this.user.lon;
        let key = constants.googleMapsApiKey;
        return `https://maps.googleapis.com/maps/api/staticmap?
                center=${lat},${lon}
                &zoom=16
                &size=500x250
                &maptype=terrain
                &markers=color:red|${lat},${lon}
                &key=${key}`
      },
      async logout() {
          Floppy.truncate('users');
          localStorage.clear('key');
          localStorage.clear('shoppingCart');
          await auth.logout();
          this.USER_LOGGED(false);
          this.$router.go();
      },

      async listenNotify() {
        const notify = database.ref('users/'+this.user.user_id);
        notify.on('value',(snapshot)=>{
          this.bage = "0";
          this.notifications = [];
          if(snapshot.val() !== null){
            this.notifications = snapshot.val();
            if(Object.keys(this.notifications.orders).length > 0){
              this.bage = String(Object.keys(this.notifications.orders).length);
              if(this.allow_sound){this.sound.play();}
            }
          }
        })
      },
      goOrder(id_order) {
        if(!this.$route.path.includes('/orders/')){
          this.$router.push('/orders/');
        }
      },
      chageLocate(item){
        if (this.$i18n.locale !== item) {
          this.$i18n.locale = item;
          }
        for (var i = 0; i < this.locales.length; i++) {
          if (this.locales[i].locale === item){
            this.indexLocale = i;
            break
          }
        }
      },
    },
    watch: {},
    computed: {
      ...mapState(['appLoader','userLogged','user','cart','products']),
    },
  };
</script>

<style type="text/css" scoped>
</style>
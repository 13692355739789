import * as constants from '@/constants';

export default {
  capitalize(_string) {
    return _string.charAt(0).toUpperCase() + _string.slice(1);
  },
  cutString(longStr, max = 20) {
      const maxChar = max;
      if (longStr.length >= maxChar) {
        return `${longStr.slice(0, maxChar)}...`;
        // return longStr.substring(0, maxChar) + '...';
      }
      return longStr;
  },
  isMail(string) {
    var expresion = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(expresion.test(string)) {
        return true;
    }else{
        return false;
      }
  },
  isUrl(string) {
    var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
    var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
    var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;
    if (typeof string !== 'string') {
        return false;
    }
    var match = string.match(protocolAndDomainRE);
    if (!match) {
        return false;
    }
    var everythingAfterProtocol = match[1];
    if (!everythingAfterProtocol) {
        return false;
    }
    if (localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol)
    ) {
        return true;
    }
    return false;
  },
  validatePass(pass) {
      var expresionRegular = /^(?=.*\d+)(?=.*[a-zA-Z])[\x20-\x7E]{6,}$/;
      if(pass.match(expresionRegular)) {
          return true;
      }else {
          return false;
      }
  },
  allowed_extension() {
      return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf', 'txt', 'csv', 'xls', 'xlsx'];
  },
  checkExt(filename) {
      var extensions = this.allowed_extension();
      var nameFile = filename.name;
      nameFile = nameFile.toLowerCase();
      var ext = nameFile.substr(nameFile.lastIndexOf('.') + 1);
      var isValid = extensions.indexOf(ext) > -1;
      return { 'valid': isValid, 'ext': ext };
  },
  formatCurrency(data, Type, Style, Typecurrency) {
    const type = Type || constants.LOCALE;
    const style = Style || 'currency';
    const currency = Typecurrency || constants.CURRENCY;
      const formatterPeso = new Intl.NumberFormat(type, {
                              style: style,
                              currency: currency,
                              maximumFractionDigits : 2
                              })
      return formatterPeso.format(data);
  },
  statusOrders(status) {
      switch(status) {
          case 1: return 'order.status_pending' ;break;
          case 2: return 'order.status_confirmed';break;
          case 3: return 'order.status_cancelled';break;
          case 4: return 'order.status_delivered';break;
          case 5: return 'order.status_to_resolve';break;
      }
  },
  colorcircleStatus(status) {
      switch(status) {
          case 1: return 'yellow';break;
          case 3: return 'red';break;
          case 4:case 2: return 'green';break;
      }
  }
};

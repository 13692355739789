import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import * as constants from '@/constants';
require('../public/setting/setting.js');
import { Loader } from '@googlemaps/js-api-loader';
const MapsLoader = new Loader({apiKey: constants.googleMapsApiKey, libraries: constants.libraries});
MapsLoader.load().then(() => {
    if(!window.google){
      (window.google = googleApi)
    }
  }).catch(e => {
    console.error('GoogleMaps Api Loader Error:', err);
  });

import Notifications from 'vue-notification'
import i18n from './i18n'
Vue.use(Notifications)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
